document.addEventListener("DOMContentLoaded", function() {
    // Get all the accordion "toggle all" buttons
    const accordionToggleButtons = document.querySelectorAll(".accordion__toggle-all");
  
    // Add click event listener to each toggle button
    accordionToggleButtons.forEach(button => {
      button.addEventListener("click", function() {
        const accordion = this.closest(".accordion"); // Find the closest accordion container
        const accordionItems = accordion.querySelectorAll(".accordion-item") // Find all accordion items in the current container
        const isClosed = this.classList.contains("accordion__toggle-all--closed")
  
        // Set the appropriate classes based on the current state
        if (isClosed) {
          accordionItems.forEach(item => {
            const collapse = item.querySelector(".accordion-collapse")
            const button = item.querySelector('.accordion-button')
            collapse.classList.add("show")
            button.setAttribute('aria-expanded', true);
        })
        this.classList.remove("accordion__toggle-all--closed")
        this.classList.add("accordion__toggle-all--opened")
    } else {
        accordionItems.forEach(item => {
            const collapse = item.querySelector(".accordion-collapse")
            const button = item.querySelector('.accordion-button')
            collapse.classList.remove("show")
            button.setAttribute('aria-expanded', false);
        })
        this.classList.remove("accordion__toggle-all--opened")
        this.classList.add("accordion__toggle-all--closed")
        }
      })
    })
  })
  