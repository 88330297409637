// Lib documentation:
// https://www.npmjs.com/package/intl-tel-input
// https://intl-tel-input.com/

document.addEventListener("DOMContentLoaded", function () {
    // Get all elements with "intl-tel-input" class
    const telInputs = document.querySelectorAll('.intl-tel-input')
    
    if(telInputs != null && telInputs.length > 0){
        telInputs.forEach((input) => {

            // get initial country from a data attribute
            const initialCountryString = input.getAttribute('data-initial-country')

            // initialize lib
            window.intlTelInput(input, {
                utilsScript: "/_/service/no.seeds.velgekte/get-assets-url/libs/intl-tel-input/build/js/utils.js",
                initialCountry: initialCountryString,
                separateDialCode: true,
                autoPlaceholder: "off"
            })
        })
    }
})